import React, { Component } from 'react';
import BackgroundSlider from 'react-background-slider'

function myRandomInts(quantity, max){
   const arr = []
   while(arr.length < quantity){
     var candidateInt = Math.floor(Math.random() * max) + 1
     if(arr.indexOf(candidateInt) === -1) arr.push(candidateInt)
   }
 return(arr)
 }

 function myRandomBG(BGs, randomvalues){
    const arr = []
    for(let step = 0; step < randomvalues.length; step++){
      arr[step] = BGs[randomvalues[step]]}
       return arr
 }


class Header extends Component {
  render() {

    if(this.props.data){
      var name = this.props.data.name;
      var occupation= this.props.data.occupation;
      // var description= this.props.data.description;
      var city= this.props.data.address.city;

      var bgdummi00 = this.props.data.background2.filter(function(item){
         return item.show === true;});

      var t = myRandomInts(3, bgdummi00.length-1)
      var obj = Object.values(bgdummi00);
      var ranbg = myRandomBG(obj, t)

      var bgdummi01 = ranbg.map((item) => require("../images/BG/" + item.pic))
      var bgdummi02 = bgdummi01.map((item) => item["default"])
 
      var networks= this.props.data.social.map(function(network){
        return(
        <li key={network.name}><a href={network.url}><i className={network.className}></i></a>
        </li>
         )        
      })
    }

    
    return (
       
      <header id="home">
              
      <nav id="nav-wrap">

         <ul id="nav" className="nav">
            <li className="current"><a className="smoothscroll" href="#home">Home</a></li>
            <li><a className="smoothscroll" href="#about">About</a></li>
            <li><a className="smoothscroll" href="#portfolio_sk">Projects</a></li>
         </ul>

      </nav>

      <div className="row banner">
         <div className="banner-text">
            <h1 className="responsive-headline">WELCOME.</h1><br /> 
            {/* <h2 className="responsive-headline"> I'm {name}.</h2> */}
            <h3>I'm <span>{name}</span>, a {city} based <span>{occupation}</span>. 
            {/* {description}. */}
            </h3>
            <hr />
            <ul className="social">
               {networks}
            </ul>
         </div>
      </div>

      <p className="scrolldown">
         <a className="smoothscroll" href="#about"><i className="icon-down-circle"></i></a>
      </p>

   <BackgroundSlider  images={bgdummi02}  duration={5} transition={2} />
   </header>
    );
  }
}

export default Header;
