import React, { Component } from 'react';

import Header from './Components/Header';
import Footer from './Components/Footer';
import About from './Components/About';

// import Resume from './Components/Resume';
// import Contact from './Components/Contact';
// import Testimonials from './Components/Testimonials';
import Portfolio from './Components/Portfolio';
import './styles/styles.scss';

import daten from "./resumeData.json";


// import ReactGA from 'react-ga';
// ReactGA.initialize('277232877');
// ReactGA.pageview(window.location.pathname + window.location.search);

class App extends Component {
  
  render() {
    // console.log(daten["portfolio"]["projects"]);
    return (
      <div className="App">
        <Header data={daten["main"]}/>
        <About data={daten["main"]}/>
        {/* <Resume data={daten["resume"]}/> */}
        <Portfolio data={daten["portfolio"]}/>
        {/* <Testimonials data={daten["testimonials"]}/> */}
        {/* <Contact data={daten["main"]}/> */}
        <Footer data={daten["main"]}/>
      </div>
    );
  }
}

export default App;
