import React, { Component } from 'react';

class About extends Component {
  render() {

    if(this.props.data){
      var name = this.props.data.name;
      // var profilepic= "./images/"+this.props.data.image; //picture from src
      var profilepic= require('../images/'+this.props.data.image).default;
      var bio = this.props.data.bio.map((item) => (<div>{item}<br /></div>));
      var email = this.props.data.email;
      // var resumeDownload = this.props.data.resumedownload;
    }

    return (
      <section id="about" >
      <div className="sk-grid">
         <div>
            <img className="profile-pic"  src={profilepic} alt="Silas Kalmbach Profile Pic" />
         </div>
               <div className="contact-details">
               <h2>About Me</h2>
                  <p>{bio}</p>
                  <h2>Contact Details</h2>
                  <p className="address">
						   <span>{name}</span><br />
						   {/* <span>{street}<br />
						         {city} {state}, {zip}
                   </span><br />
						   <span>{phone}</span><br /> */}
                     <a href={"mailto:" + email} style={{color: "#7A7A7A"}}>{email}</a>
                     {/* <span>{email}</span> */}
					   </p>
               </div>
               {/* <div className="columns download">
                  <p>
                     <a href={resumeDownload} className="button"><i className="fa fa-download"></i>Download Resume</a>
                  </p>
               </div> */}
            {/* </div>
         </div> */}
      </div>

   </section>
    );
  }
}

export default About;
