import React, { Component } from 'react';

class Portfolio extends Component {
  
  
  render() {

    if(this.props.data){
      var projects = this.props.data.projects.filter(function(item){
        return item.show === true;});
      projects = projects.map(function(projects){

          var projectImage = require('../projects/'+projects.image).default; //From Webpack
          // var projectVideo = require('../projects/'+projects.video).default; //From Webpack
          return <div key={projects.title} className="columns portfolio-item">
            <div className="item-wrap">
              {/* <a href={projects.url} title={projects.title}> */}
              {/* if(projects.video){
                return <h1>"Das hier ist ein Test"</h1>
              }  */}
                <div className= "sk-padding"><img className="sk-rund" alt={projects.title} src={projectImage} />
                </div>
                
                <div className="overlay">
                    <div className="portfolio-item-meta">
                  <h5>{projects.title}</h5>
                      <p>{projects.category}</p>
                    </div>
                  </div>
                {/* <div className="link-icon"><i className="fa fa-link"></i></div> */}

              {/* </a> */}
            </div>
            </div>

      }
      )
  }

  return (
    
    <section id="portfolio_sk">
      <div  style={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center"}}>
        <h1 style={{color: "hsl(0, 0%, 100%)", width: "80%"}}
      >Check out some of my spare time projects.</h1></div>

          <div className="sk-grid">
              {projects}
          </div>
 </section>
  );
  }
}

export default Portfolio;
